import { enableProdMode } from '@angular/core';
import localFavicon from './logo/favicon-local.png';
import stagingFavicon from './logo/favicon-staging.png';
import './polyfills';

export async function main(
  module: () => Promise<any>,
  config: { production: boolean; environment: string },
) {
  if (config.production) {
    enableProdMode();
  }

  // Sett the favicon based on environment
  if (config.environment.startsWith('staging')) {
    document.querySelector('#favicon')!.setAttribute('href', stagingFavicon);
  } else {
    switch (config.environment) {
      case 'local':
        document.querySelector('#favicon')!.setAttribute('href', localFavicon);
        break;
    }
  }

  if (typeof ngJitMode === 'undefined' || ngJitMode) {
    await import('./angular-dev');
    const { platformBrowserDynamic } = await import(
      '@angular/platform-browser-dynamic'
    );

    await platformBrowserDynamic().bootstrapModule(await module(), {
      preserveWhitespaces: false,
    });
  } else {
    const { platformBrowser } = await import('@angular/platform-browser');
    await platformBrowser().bootstrapModule(await module(), {
      preserveWhitespaces: false,
    });
  }
}
